import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../organisatieeffectiviteit.scss"
import kantoor from "../../images/reattach.jpg"
import bgcase from "../../images/bigsteps.jpg"

const Reattachcoachopleiding = () => {
  return (
    <Layout>
      <SEO
        title="ReAttach Coachopleiding"
        description="Wil je een erkende ReAttach® Coach worden? 
        Deze professionele ReAttach coachopleiding wordt gegeven in de Zipperr®
        Academie, ons trainingscentrum in Nuenen, Noord Brabant."
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${kantoor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        {/*<div className="tiny-header centerText">Breinkracht organisatieadvies op basis van de Zipperr® Methode</div>*/}
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              ReAttach Coachopleiding
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Creëren van balans</p>
        </div>
        <p>
          Tijdens de coachopleiding van de Zipperr®Academie leren onze cursisten
          een nieuwe interventie, waarmee cliënten met stress, belemmeringen en
          trauma's geholpen kunnen worden. Voor alle doelgroepen is de
          basistraining gelijk. Grondlegger van deze doeltreffende methode is
          Drs. Paula Weerkamp-Bartholomeus.
          Een certificaat van deelname kan worden uitgereikt na het volledig
          doorlopen van deze 3 studiedagen. Registratie en diploma is
          voorbehouden aan cursisten die succesvolle resultaten behalen bij
          minimaal 5 casuïstieken.
        </p>
      </div>

      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>Wil jij een erkende ReAttach® Coach worden?</h2>
          <p>
            Dag 1: Het eerste gedeelte van de ReAttach Coachopleiding staat in
            het teken van het versterken van affectief mentaliseren, van
            affectief mentaliseren onder optimale alertheid en Multiple Sensory
            Integration Processing: Je leert het verwerken van informatie,
            emoties en gebeurtenissen bij de cliënten te optimaliseren met een
            techniek die zowel lichaamsgerichte als psychologische elementen
            bevat. Vervolgens word je getraind in het geven van een sociaal
            cognitieve training die cliënten helpt om hun informatie beter te
            ordenen.
          </p>
          <p>
            Dag 2: Het tweede gedeelte van het ReAttach Protocol betreft
            Cognitive Bias Modification, pro-actieve coping en het activeren van
            nieuwe ontwikkelingsmogelijkheden. U krijgt de tools om cliënten te
            helpen zichzelf en anderen te accepteren en positiever in het leven
            te staan. Daarnaast word je getraind in het activeren van het
            vermogen om problemen actief aan te pakken en te groeien in
            persoonlijke ontwikkeling.
          </p>
          <p>
            Dag 3: ReAttach als een systemische interventie te gebruiken als
            aanvullende expertise binnen jouw eigen vakgebied. De vaardigheden
            worden geautomatiseerd en je leert bewust bekwaam ReAttach toe te
            passen: ReAttach is een gezinsinterventie als er wordt gewerkt met
            kinderen en jeugdigen. Bij volwassenen is het verstandig partners of
            mantelzorgers te betrekken. De derde opleidingsdag leer je hoe je
            ReAttach in kunt passen in jouw huidige werkwijze en in combinatie
            met andere methoden waar je gebruikt van maakt.
          </p>
          <p>
            De training wordt gegeven in de Zipperr®Academie, ons
            trainingscentrum in Nuenen, Noord Brabant.
          </p>
          <p className={"btn-container"}>
            <Link
              to={"/contact"}
              className="btn primary-lg round expand-on-hover"
            >
              Aanmelden of meer informatie
            </Link>
          </p>
        </div>
        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${bgcase})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImageMobile: file(relativePath: { eq: "briefcase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageLaptop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageDesktop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 1200, height: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ziperCaseImg: file(relativePath: { eq: "ad-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Reattachcoachopleiding
